import moment from "moment"
import { getTourLink, getTourPrice } from "./tour"
import { BASE_URL } from "./constants"
import { useTranslation } from "react-i18next"

const getStructuredDataTourReviews = tour => {
  const reviews = []

  {
    tour.reviews?.map(review => {
      reviews.push({
        author: {
          name: review.user.firstName,
          type: "Person",
        },
        datePublished: moment(review.created_at).format("D MMM YYYY"),
        reviewBody: review.description,
        name: review.title,
        reviewRating: {
          bestRating: "5",
          ratingValue: review.rating,
          worstRating: "1",
        },
      })
    })
  }

  return reviews
}

const getStructuredDataforToursPage = tours => {
  const { i18n: l } = useTranslation()

  const offers = []
  let reviews = []
  let pictures = []

  tours.map(tour => {
    const { price: tourPrice, currency: tourCurrency } = getTourPrice(tour.node)
    const tourLink = l ? getTourLink(tour.node, l) : ""
    const absoluteTourLink = `${BASE_URL[l.language]}${tourLink}`

    reviews = reviews.concat(getStructuredDataTourReviews(tour.node))
    pictures = pictures.concat(getImagesURL(tour.node.pictures))
    offers.push({
      price: tourPrice,
      priceCurrency: "EUR",
      priceValidUntil: moment(tour.node.sessions[0].start).format("YYYY-MM-DD"),
      itemCondition: "NewCondition",
      availability: "OnlineOnly",
      url: absoluteTourLink,
      seller: {
        name: "WeShoot",
        type: "Organization",
      },
    })
  })

  return { offers, reviews, pictures }
}

const getImagesURL = pictures => {
  const picturesArray = []
  pictures.map(p => {
    p.image.map(image => {
      if (image.formats.medium && image.formats.medium.url) {
        return picturesArray.push(image.formats.medium.url)
      }
    })
    return p
  })
  return picturesArray
}

export {
  getStructuredDataTourReviews,
  getImagesURL,
  getStructuredDataforToursPage,
}
