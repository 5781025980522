import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "../../base/typography"
import TourCard from "../../base/TourCard"
import { useTranslation } from "react-i18next"
import Button from "../../base/Button"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import Link from "../../base/Link"

const ButtonsSection = () => {
  return (
    <div className={classnames([styles.buttonsContainer])}>
      <Link variant={"redOutline"} to={"/viaggi-fotografici/destinazioni/"}>
        <img
          src={"/media/images/icons/destinations.png"}
          width="30"
          height="30"
          alt="Destinazioni viaggi fotografici"
        />
        Destinazioni
      </Link>
      <Link to={"/viaggi-fotografici/calendario/"} variant={"redOutline"}>
        <img
          src={"/media/images/icons/calendar.gif"}
          width="30"
          height="30"
          alt="Calendario viaggi fotografici"
        />
        Calendario
      </Link>
      <Link to={"/viaggi-fotografici/collezioni/"} variant={"redOutline"}>
        <img
          src={"/media/images/icons/collections.png"}
          width="30"
          height="30"
          alt="Collezioni viaggi fotografici"
        />
        Collezioni
      </Link>
      <Link
        to={"/viaggi-fotografici/collezioni/weekend-italiani"}
        variant={"redOutline"}
      >
        <img
          src={"/media/images/icons/italy.png"}
          width="30"
          height="30"
          alt=" viaggi fotografici in Italia"
        />
        Weekend in Italia
      </Link>
    </div>
  )
}

export default ButtonsSection
