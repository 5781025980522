import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../components/ui/base/layout/header"
import Breadcrumbs from "../components/ui/base/Breadcrumbs"
import Section from "../components/ui/base/Section"
import TourCard from "../components/ui/base/TourCard"
import { createMarkup } from "../util/tour"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useTranslation } from "react-i18next"
import { BASE_URL, PHOTO_TOURS_URL } from "../util/constants"
import Faq from "../components/ui/base/Faq"
import { FAQJsonLd, ProductJsonLd } from "gatsby-plugin-next-seo"
import { getStructuredDataforToursPage } from "../util/seo"
import SocialProofSection from "../components/ui/extended/SocialProofSection"
import LastMinuteSection from "../components/ui/extended/LastMinuteSection"
import Button from "../components/ui/base/Button"
import ButtonssSection from "../components/ui/extended/ButtonsSection"
import ButtonsSection from "../components/ui/extended/ButtonsSection"

const ToursPage = () => {
  const { i18n: l } = useTranslation()
  const data = useStaticQuery(query)
  const toursPage = data.strapiToursPage
  const SEO = toursPage.SEO[0]
  const tours = data.allStrapiTour.edges
  const faqs = toursPage.faqs

  const { offers, reviews, pictures } = getStructuredDataforToursPage(tours)

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={SEO.metaTitle}
        description={SEO.metaDescription}
        canonical={`${BASE_URL[l.language]}/${PHOTO_TOURS_URL[l.language]}/`}
        language={l.language}
      />
      <ProductJsonLd
        productName={SEO.metaTitle}
        description={SEO.metaDescription}
        canonical={`${BASE_URL[l.language]}/${PHOTO_TOURS_URL[l.language]}/`}
        brand="WeShoot"
        sku={0}
        mpn={0}
        images={pictures}
        reviews={reviews}
        aggregateRating={{
          ratingValue: "5",
          reviewCount: reviews.length,
        }}
        offers={offers}
      />
      <Header
        size={"compact"}
        videoURL={"weshoot-viaggi-fotografici"}
        theme="dark"
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  {toursPage.title}
                </Typography>
                <Breadcrumbs
                  elements={[
                    {
                      name: "WeShoot",
                      path: "/",
                    },
                    {
                      name: "Viaggi Fotografici",
                      path: `/${PHOTO_TOURS_URL[l.language]}/`,
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>
      <ButtonsSection />
      <SocialProofSection />

      <Section variant={"white"}>
        <div
          className="mb-5"
          dangerouslySetInnerHTML={createMarkup(toursPage.description)}
        />
        {tours && tours.length > 0 && (
          <Row>
            {tours.map((tour, i) => {
              return (
                <>
                  <Col lg={4} md={6} className="zoomIn mb-2" key={i}>
                    <TourCard tour={tour.node} />
                  </Col>
                </>
              )
            })}
          </Row>
        )}
      </Section>

      <LastMinuteSection />

      {faqs && faqs.length > 0 && (
        <Section
          variant={"grey"}
          title={`Domande piú frequenti sui Viaggi fotografici`}
        >
          {faqs?.map(q => {
            return <Faq faq={q} />
          })}
          <FAQJsonLd questions={faqs} />
        </Section>
      )}
    </>
  )
}

export const query = graphql`
  {
    strapiToursPage {
      SEO {
        metaDescription
        metaTitle
      }
      cover {
        localFile {
          ...ImageFragment
        }
      }
      description
      title
      faqs {
        question
        answer
        id
      }
    }
    allStrapiTour(sort: { fields: priority }) {
      edges {
        node {
          id
          tour_id
          image {
            url
            localFile {
              ...ImageFragment
            }
          }
          SEO {
            metaTitle
            metaDescription
            shareImage {
              formats {
                medium {
                  url
                  width
                  height
                }
              }
            }
          }
          pictures {
            id
            title
            type
            image {
              localFile {
                ...ImageFragment
              }
              formats {
                medium {
                  url
                }
              }
            }
          }
          reviews {
            created_at
            description
            title
            rating
            user {
              firstName
            }
          }
          slug
          states {
            slug
          }
          places {
            slug
          }
          sessions {
            end
            id
            sessionId
            start
            status
            maxPax
            balance
            price
            deposit
            currency
            users {
              id
              firstName
              instagram
              profilePicture {
                localFile {
                  ...ImageFragment
                }
              }
            }
          }
          excerpt
          experienceLevel
          title
        }
      }
    }
  }
`

export default ToursPage
