import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import classnames from "classnames"
import * as styles from "./index.module.scss"

const SocialProofSection = () => {
  const data = useStaticQuery(query)
  const picturess = data?.allFile.edges
  return (
    <div className={classnames([styles.social_proof_container])}>
      <div className={classnames([styles.proof])}>
        1200 Viaggiatori, 100+ Viaggi organizzati, 18.000 WeShooters dal 2015
        con pubblicazioni su
      </div>
      <div className={classnames([styles.logo_container])}>
        {picturess.map(p => {
          return (
            <GatsbyImage
              alt={"Pubblicazioni Viaggi Fotografici"}
              loading="eager"
              className={classnames([styles.social_proof])}
              image={p.node.childImageSharp.gatsbyImageData}
            />
          )
        })}
      </div>
    </div>
  )
}
export const query = graphql`
  query Brands {
    allFile(filter: { name: { regex: "/pubblicazioni-/" } }) {
      edges {
        node {
          ...ImageFragment
        }
      }
    }
  }
`

export default SocialProofSection
